import React from "react";
import Flip from 'react-reveal/Flip';

export const Clients = (props) => {
  return (
    <div id="testimonial">
      <div className="container testimonial-container">
        <div className="section-title text-center">
          <h2>Testimonails</h2>
          <p>
            {props.data ? props.data.testimonial : "Testimonial loading"}
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.data.map((d, i) => (
              <Flip top>
                <div key={`${d.name}-${i}`} className="zoom col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt="" />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p align="justify">"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              </Flip>
            ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
