import React from "react";
import Slide from 'react-reveal/Slide';
import PartnerCarousel from "../compo/partnerCarsousel/PartnerCarousel";

export const Partners = (props) => {

  return (
    <div id="clients" className="text-center">
      <div className="container">
        <div style={{ marginTop: '20px' }} className="col-md-10 col-md-offset-1 section-title">
          <h2>Clients</h2>
          <p>
            {props.data ? props.data.subheader : "Loading..."}
          </p>
        </div>
      </div>
      <Slide right>
      { props.data ?  <PartnerCarousel data={props.data.data}/> : "Loading"}
      </Slide>

    </div>
  );
};