import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

import Flip from 'react-reveal/Flip';
import { SocialIcon } from 'react-social-icons';

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    alert("Parsing contact info");
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm("service_5fkd7m2", "template_bou37ms", e.target, "4s3WRvox2bV5Ow319")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          alert("Sent Success ✅");
        },
        (error) => {
          console.log(error.text);
          alert("Please try again later ❌");
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">


          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <section>
                <div className="contact-container">
                  <div className="screen">
                    <div className="screen-header">
                      <div className="screen-header-left">
                        <div className="screen-header-button close"></div>
                        <div className="screen-header-button maximize"></div>
                        <div className="screen-header-button minimize"></div>
                      </div>
                      <div className="screen-header-right">
                        <div className="screen-header-ellipsis"></div>
                        <div className="screen-header-ellipsis"></div>
                        <div className="screen-header-ellipsis"></div>
                      </div>
                    </div>
                    <div className="screen-body">
                      <div className="screen-body-item left">
                        <div className="app-title">
                          <span>CONTACT</span>
                          <span>US</span>
                        </div>
                        <div className="app-contact">PLEASE DO NOT SPAM</div>
                      </div>
                      <div className="screen-body-item">
                        <form name="sentMessage" validate onSubmit={handleSubmit}>
                          <div className="app-form">
                            <div className="app-form-group">
                              <input
                                type="text"
                                id="name"
                                name="name"
                                className="app-form-control"
                                placeholder="NAME"
                                required
                                onChange={handleChange} />
                            </div>
                            <div className="app-form-group">
                              <input
                                type="email"
                                id="email"
                                name="email"
                                className="app-form-control"
                                placeholder="EMAIL"
                                required
                                onChange={handleChange} />
                            </div>
                            <div className="app-form-group">
                              <input
                                className="app-form-control"
                                placeholder="CONTACT NO" />
                            </div>
                            <div className="app-form-group message">
                              <input
                                name="message"
                                id="message"
                                className="app-form-control"
                                placeholder="MESSAGE"
                                required
                                onChange={handleChange} />
                            </div>
                            <div id="success"></div>
                            <div className="app-form-group buttons">
                              <button className="app-form-button">CANCEL</button>
                              <button type="submit" className="app-form-button">SEND</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div style={{ marginTop: '200px' }} className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              {props.data &&
                <div className="social">
                  <Flip bottom>
                    <ul>
                      <li style={{ backgroundColor: 'white', borderRadius: '100%', padding: '3px' }}>
                        <SocialIcon target="_blank" className="zoom" url={props.data.twitter} />
                      </li>
                      <li style={{ backgroundColor: 'white', borderRadius: '100%', padding: '3px' }}>
                        <SocialIcon target="_blank" className="zoom" url={props.data.linkedin} />
                      </li>
                      <li style={{ backgroundColor: 'white', borderRadius: '100%', padding: '3px' }}>
                        <SocialIcon target="_blank" className="zoom" url={props.data.instagram} />
                      </li>
                    </ul>
                  </Flip>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
