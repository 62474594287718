import { Image } from "../compo/gallery/image";
import React, { useState, useEffect } from "react";
import useScreenType from "react-screentype-hook";

import Carousel3d from "../compo/gallery/Carousel3d";

import Zoom from 'react-reveal/Zoom';
import Roll from 'react-reveal/Roll';
import HorizontalScrollList from "../compo/list/HorizontalScrollList";

export const Gallery = (props) => {

  const screenType = useScreenType();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [disNum, setDisNum] = useState(6); //max 6 images

  useEffect(() => {
    if (screenType.isMobile) { setDisNum(2) };
  }, [screenType])

  return (
    <div id="gallery" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{props.data ? props.data.collegeName : "Loading..."}</h2>
          {/*<h2>Gallery</h2>*/}
          <div>
            <br />
            <center>
              <div className="courseHeader">
                <p>COURSES HANDLED</p>
              </div>
            </center>
            <HorizontalScrollList dataList={props.data ? props.data.coursesHandled : ["Loading..."]} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Zoom bottom>
            <div style={{ width: 600, height: "70vh" }}>
              <Carousel3d
                setCurrentIndex={setCurrentIndex}
                cards={props.data ? props.data.data : [{ key: 1, title: "Loading", image: 'https://i.imgur.com/FkZ8zcY.gif' }]}
                offset={2}
              />
            </div>
          </Zoom>
        </div>
        <div className="images-container row">
          <div style={{ padding: '15px' }} className="portfolio-items">
            {props.data
              ? props.data.data[currentIndex].Gallery.filter((item, index) => index < disNum).map((d, i) => (
                <Roll top>
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      image={d.image}
                    />
                  </div>
                </Roll>
              ))
              : "Loading..."}
          </div>
        </div>
      </div>

      <div className="button-36-gallery">
        <button onClick={() => { window.location.href = '/#clients'; }} className="button-36">Home</button>
      </div>

    </div>
  );
};
