import React from "react";
import Slide from 'react-reveal/Slide';

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div style={{ marginTop: '20px' }} className="col-md-10 col-md-offset-1 section-title">
          <h2>Features</h2>
        </div>

        <div className="row" style={{marginBottom:'25px'}}>
          {props.data
            ? props.data.map((d, i) => (
              
                <div key={`${d.title}-${i}`} className="zoom col-md-3">
                  {" "}
                  <Slide left>
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                  </Slide>
                </div>
            ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
