import React, { useState } from 'react';
import {isMobile} from 'react-device-detect';
import { Carousel } from 'react-responsive-carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import arrow icons
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Card from './Card';
import './PartnerCarousel.css';

const PartnerCarousel = (props) => {

  const [currentCard, setCurrentCard] = useState(0);

  const handleOnSelect = (index) => {
    setCurrentCard(index);
  };

  console.log(isMobile ? "mobile" : "laptop");

  return (
    <div style={{paddingRight:'7%', paddingLeft:'7%'}}>
    <Carousel
      showArrows={true}
      showStatus={false}
      showIndicators={true}
      showThumbs={false}
      infiniteLoop={true} // Set infinite loop to true
      emulateTouch={false}
      selectedItem={currentCard} // Set the selected item based on state
      onSelect={handleOnSelect} // Handle card selection
      centerSlidePercentage={isMobile ? 100 : 33.33} // Display three slides at a time (333-> 3) (100 -> 1)
      centerMode={true}
      swipeable={true}
      autoPlay={true}
      interval={1575} // Set the time threshold for auto scroll to 2 seconds
      stopOnHover={true}

      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            className="carousel-arrow carousel-arrow-prev"
          >
            <FaChevronLeft />
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            className="carousel-arrow carousel-arrow-next"
          >
            <FaChevronRight />
          </button>
        )
      }
    >
      {props.data ? 
        props.data.map((d,i) => (
          <div key={d}>
          <Card isActive={currentCard === i} data={d}/>
        </div>
        ))
        :"Loading"}


    </Carousel>
    </div>
  );
};

export default PartnerCarousel;