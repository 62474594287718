import React, { useState, useEffect } from "react";
import { config } from "react-spring";
import { Carousel } from "react-carousel-card-3d";
import CarouselCard from "./CarouselCard";

export default function Carousel3d({
  cards,
  offset,
  autorotation = true,
  rotationInterval = 5000,

  setCurrentIndex
}) {
  const [offsetRadius, setOffsetRadius] = useState(2);
  const [goToSlide, setGoToSlide] = useState(0);

  useEffect(() => {
    setOffsetRadius(offset);
  }, [offset]);

  useEffect(() => {
    let interval = null;
    if (autorotation) {
      interval = setInterval(() => {
        setGoToSlide((prevState) =>
          prevState + 1 >= cards.length ? 0 : prevState + 1
        );
        setCurrentIndex((prevState) =>
        prevState + 1 >= cards.length ? 0 : prevState + 1
      );
      }, rotationInterval);
    }

    return () => {
      if (autorotation && interval) {
        clearInterval(interval);
      }
    };
  }, [cards, autorotation, rotationInterval, setCurrentIndex]);

  const table = cards.map((card, index) => {
    return {
      key: card.key,
      content: (
        <CarouselCard
          key={card.key}
          title={card.title}
          image={card.image}
          currentSlide={goToSlide}
          currentIndex={index}
        />
      ),
      onClick: () => {setGoToSlide(index);
                      setCurrentIndex(index);
                      console.log("Gallery scrolled to", index);},
    };
  });

  return (
    <Carousel
      slides={table}
      goToSlide={goToSlide}
      offsetRadius={offsetRadius}
      animationConfig={config.gentle}
    />
  );
}
