import React from "react";
import HorizontalScrollList from "../compo/list/HorizontalScrollList";
import Slide from 'react-reveal/Slide';
import { FaCheckCircle } from 'react-icons/fa'; // Import your desired icon 

export const Course = (props) => {
    return (
        <div id="course" className="text-center">
            <div className="container">

                <div className="section-title">
                    <h2>{props.data ? props.data.courseName : "Loading..."}</h2>
                    <HorizontalScrollList dataList={props.data ? props.data.parts : ["Loading..."]} />
                </div>

                <div className="oContainer">
                    <div className="oSection">
                        <Slide bottom>
                            <div className="oContent">
                                <h1>Course Overview</h1>
                                <p align="justify">{props.data ? props.data.overview : "Loading..."}</p>
                            </div>
                            <div className="oContent">
                                <h1>Course Outcome</h1>
                                <p align="justify">{props.data ? props.data.outcome : "Loading..."}</p>
                            </div>
                        </Slide>
                    </div>
                </div>

                <div className="list-container-center">
                    <h1>Course Highlights</h1>
                    <ul style={{ marginTop: '2.5' }}>
                        {props.data ? props.data.hilights.map((item, index) => (
                            <li key={index} className="list-item zoom">
                                <FaCheckCircle className="list-icon" />
                                {item}
                            </li>
                        )) : "Loading"}
                    </ul>
                </div>

                <div>
                    <h1 style={{ marginTop: '2.75%', marginBottom: '-0.75%' }}>High level view</h1>
                    <main>
                        <ol className="gradient-list">
                            {props.data ? props.data.highLevelViews.map((item, index) => (
                                <Slide right key={index}><li className="zoom">{item}</li></Slide>
                            )) : ["Loading"]}
                        </ol>
                    </main>
                </div>

                <div style={{ marginTop: '5%' }}>
                    <button onClick={() => { window.location.href = '/#courses'; }} className="button-36">Other Courses</button>  <button onClick={() => { window.location.href = '/#contact'; }} className="button-36">Contact Now</button>
                </div>

            </div>
        </div>
    );
};
