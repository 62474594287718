export const Footer = () => {
    return(
        <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 RAMPeX Technologies
          </p>
        </div>
      </div>
    )
}