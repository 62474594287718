import React from "react";
import Slide from 'react-reveal/Slide';

export const Courses = (props) => {
  return (
    <div id="courses" className="text-center">

      <link rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" />

      <div className="container">
        <div className="section-title">
          <h2>Our Courses</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.data.map((d, i) => (
              <Slide top>
                <div key={`${d.name}-${i}`} className="zoom col-md-4">
                  {" "}
                  {/*<a href={d.route}>*/}
                  <a href={"#courses"}>
                    <i className={`${d.icon} zoom`}></i>
                  </a>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              </Slide>
            ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
