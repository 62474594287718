import React from 'react';

const HorizontalScrollList = ({ dataList }) => {

const formattedList = dataList.flatMap((item, index, array) =>
  index !== array.length - 1 ? [item, " | "] : [item]
);

  return (
    <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
      {formattedList.map((item, index) => (
        <div key={index} style={{ display: 'inline-block', margin: '0 10px' }}>
          <p>{item}</p> 
        </div>
      ))}
    </div>
  );
};

export default HorizontalScrollList;
