import React from 'react';
import './Card.css'; // Make sure to import your CSS file for styling

const Card = (props) => {
  return (
    <>
    {/*<a href={props.data.route}>  disabling gallery routing temp  */}
    <a href={"#clients"}>
      <div className="card-container">
        <div className="card">
          <div className="image-container">
            <img src={props.data.bg} alt={props.data.title} className="card-image" />
          </div>
          <div className="text-box">
            <img className="card-clg-banner" src={props.data.header} alt='college header' />
            <h2 className="card-title">{props.data.title}</h2>
            <p className="card-description">{props.data.text}</p>
          </div>
        </div>
      </div>
    </a>
    </>
  );
};

export default Card;
