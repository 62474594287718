import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Navigation } from "./components/common/navigation";
import { Footer } from "./components/common/footer";

import { Header } from "./components/home/header";
import { Features } from "./components/home/features";
import { About } from "./components/home/about";
import { Courses } from "./components/home/courses";
import { Partners } from "./components/home/partners";
import { Clients } from "./components/home/clients";
import { Team } from "./components/home/Team"
import { Contact } from "./components/home/contact";

import { Gallery } from "./components/pages/gallery";
import { Course } from "./components/pages/course";

import SmoothScroll from "smooth-scroll";

import JsonData from "./data/data.json";
import ScrollToTopButton from "./components/common/scrollToTopButton"; // Update the import
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <div>
        <Navigation />
        <Switch>

          {/* Parent Route */}
          <Route exact path="/">
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <About data={landingPageData.About} />
            <Courses data={landingPageData.Courses} />
            <Partners data={landingPageData.Partners} />
            <Clients data={landingPageData.Clients} />
            {/*<Team data={landingPageData.Team} />*/}
            <Contact data={landingPageData.Contact} />
            <ScrollToTopButton />
            <Footer />
          </Route>

          {/* Induvidual gallery routes */}
          <Route path="/gallery-bit">
            <Gallery data={landingPageData.GalleryBit} />
            <Footer />       
          </Route>
          <Route path="/gallery-kct">
            <Gallery data={landingPageData.GalleryKct} />
            <Footer />     
          </Route>
          <Route path="/gallery-shriram">
            <Gallery data={landingPageData.GalleryShriram} />
            <Footer />     
          </Route>
          <Route path="/gallery-ngp">
            <Gallery data={landingPageData.GalleryNgp} />
            <Footer />     
          </Route>
          <Route path="/gallery-ebc">
            <Gallery data={landingPageData.GalleryEbc} />
            <Footer />     
          </Route>

          {/* Courses routes */}
          
          <Route path="/mern">
            <Course data={landingPageData.course_mern} />
            <Footer />     
          </Route>

          <Route path="/mean">
            <Course data={landingPageData.course_mean} />
            <Footer />
          </Route>

          <Route path="/programming-languages">
            <Course data={landingPageData.course_pl} />
            <Footer />
          </Route>

          <Route path="/fullstack-java">
            <Course data={landingPageData.course_fl_java} />
            <Footer />
          </Route>

          <Route path="/fullstack-python">
            <Course data={landingPageData.course_fl_python} />
            <Footer />
          </Route>

          <Route path="/dsa">
            <Course data={landingPageData.course_dsa} />
            <Footer />
          </Route>

        </Switch>
      </div>
    </Router>
  );
};

export default App;
