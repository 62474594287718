import React, { useState, useRef, useEffect } from "react";

import GlitchText from "react-glitch-effect/core/GlitchText";
import TextTransition, { presets } from 'react-text-transition';
import Zoom from 'react-reveal/Zoom';

import GLOBE from "vanta/dist/vanta.globe.min";
import * as THREE from "three";


export const Header = (props) => {

  const [index, setIndex] = useState(0);

  const [vantaEffect, setVantaEffect] = useState(0);
  const vantaRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        GLOBE({
          el: vantaRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 600.0,
          minWidth: 600.0,
          scale: 1.0,
          scaleMobile: 1.0,
          color: 0x6bc7,
          size: 1.80
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <header id="header">
      <div id="intro" className="intro">
        <div className="vanta" ref={vantaRef}></div>
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="box">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <div className="content">
                  <div className="col-md-8 col-md-offset-2 intro-text">

                    <Zoom bottom>
                      <h1>
                        <GlitchText color1={"#4ca8e5"} color2="#7a334c" component="h1" duration={50}><h1 className="rampex">RAMPeX</h1></GlitchText>
                        TECHNOLOGIES
                        <span></span>
                      </h1>

                      <p>
                        {props.data ?
                          <TextTransition className="text-transition" springConfig={presets.wobbly}>
                            {props.data.slogans[index % props.data.slogans.length]}
                          </TextTransition>
                        : "Loading"}
                      </p>
                    </Zoom>
                    <a
                      href="#features"
                      className="btn btn-custom btn-lg page-scroll more-butt zoom"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </header>
  );
};
